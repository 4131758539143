<template>
  <div>
    <a-modal v-model="visbile_modal" @cancel="() => $emit('controlModalAddToCart', false)"
      :title="$t('purchase.catalogue.addCart')" :footer="null" width="475px">
      <div class="mt-1">
        <div class="product-detail">
          <img
            :src="`${selected_product?.showimg_url || selected_product?.showing_url || 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531'}?tr=w-150,h-150,cm-pad_resize,bg-FFFFFF`" />
          <div class="title">
            {{ selected_product.title }}
            <p class="sku mt-2">SKU: {{ selectedVariant?.sku }}</p>
          </div>
        </div>
        <div class="mb-3 mt-3" v-if="selected_product?.warehouses?.length > 1">
          <div class="choose_variant mb-2"> Dikirim Dari</div>
          <a-select v-model="selectedWarehouse" size="large" show-search placeholder="Pilih gudang" class="w-100">
            <a-select-option v-for="(item, index) in selected_product.warehouses" :key="index" :value="item.id">
              {{ `${item.title} - ${item.city}` }}
            </a-select-option>
          </a-select>
        </div>
        <div class="mb-3 mt-4" v-else>
          <div class="choose_variant">Dikirim Dari</div>
          <div class="sku mb-3">{{ `${selected_product.warehouses[0].title} - ${selected_product.warehouses[0].city}` }}</div>
        </div>
        <template v-if="selectedWarehouse">
          <a-row v-if="selected_product && variants" class="mt-3">
            <a-col v-if="variants.length > 1" class="choose_variant"> {{ $t('purchase.catalogue.select_variant') }}
            </a-col>
            <a-col :span="24" class="mb-3 variant" v-if="variants && variants.length > 1">
              <div class="d-flex justify-content-start align-items-center flex-wrap">
                <!-- <div class="variant_title mr-4"> {{ variants[0].varian }} </div> -->
                <a v-for="(item, index) in variants" :key="index"
                  class="variant_option my-2 mr-2 d-flex justify-content-center align-items-center"
                  @click.prevent="() => handleChangeVariant(item.catalog_id, item)"
                  :class="{ active: selectedVariant && selectedVariant.id === item.catalog_id }">
                  <div>
                    {{ item.title }}
                  </div>
                </a>
              </div>
            </a-col>
          </a-row>
          <a-col class="sku d-flex align-items-center mb-3">
            <div class="mr-2"> Stok : </div>
            <div v-if="isPreorder" style="color: var(--biz-accent-success-toast)"> {{ $t('purchase.catalogue.preorderStock') }} </div>
            <div v-else-if="stock" style="color: var(--biz-accent-success-toast)"> {{ $t('purchase.catalogue.inStock') }}</div>
            <div v-else style="color: var(--biz-accent-failed-toast)"> {{ $t('purchase.catalogue.outOfStock') }} </div>
          </a-col>
          <div v-if="unitList?.length > 0" class="biz-grid biz-grid-cols-2 mb-4" style="gap: 1rem;">
            <div>
              <div class="choose_variant mb-2"> Jumlah </div>
              <div class="select-box d-flex justify-content-between align-items-center">
                <div @click.prevent="updateQty('min')" style="cursor: pointer"> - </div>
                <div>
                  <a-input class="centered-input input-add-cart" :value="qty" @keypress="validateQty($event)"
                    @input="updateQty" @blur="handleNullQty" />
                </div>
                <div @click.prevent="updateQty('add')" style="cursor: pointer"> + </div>
              </div>
            </div>
            <div>
              <div>
                <div class="choose_variant mb-2"> Unit </div>
                <div class="select-box" style="cursor: pointer">
                  <a-dropdown :trigger="['click']">
                    <div class="d-flex justify-content-between align-items-center">
                      <div v-if="getDiscountLabel(selected_product)?.length > 0 ">
                        {{
                          unitSelected ? `${unitSelected.uom} ${unitSelected?.selling_price ? '-' : ''} ${isLoyalty
                          ? '' :
                          'Rp'}
                          ${
                            toCurrency(calculatePrice(unitSelected.calc_price_sales_discount, unitSelected.percentage_price_sales,
                            unitSelected.selling_price,
                            getDiscountLabel(selected_product)?.[0]?.promotions?.[0]?.gift?.[0]?.value,
                            getDiscountLabel(selected_product)?.[0]?.promotions?.[0]?.gift?.[0]))
                          }` : $t('purchase.catalogue.select_unit')
                        }}
                      </div>
                      <div v-else>
                        {{
                          unitSelected ? `${unitSelected.uom} ${unitSelected?.selling_price ? '-' : ''} ${isLoyalty
                          ? '' :
                          'Rp'} ${toCurrency(unitSelected.selling_price)}` : $t('purchase.catalogue.select_unit')
                        }}
                      </div>
                      <!-- <div> - </div>
                    <div> {{ isLoyalty ? '' : 'Rp ' }}{{ toCurrency(unitSelected?.selling_price)}}  </div> -->
                      <div> <a-icon type="down" /> </div>
                    </div>
                    <a-menu slot="overlay" @click="handleSelectUom">
                      <a-menu-item v-for="(item) in unitList" :key="item.uom" class="row">
                        <div class="col-24 d-flex align-items-center" style="gap: 10px;">
                          <div>{{ item.uom }}</div>
                          <div>{{ item?.selling_price ? '-' : '' }}</div>
                          <div v-if="getDiscountLabel(selected_product)?.length > 0 ">
                            <div style="text-decoration: line-through; color: #a7a9c9; font-size: 12px;">
                              {{ isLoyalty ? '' : 'Rp' }}
                              {{ toCurrency(item.selling_price) }}
                            </div>
                            <div>
                              {{ isLoyalty ? '' : 'Rp' }}
                              {{
                                toCurrency(calculatePrice(item.calc_price_sales_discount, item.percentage_price_sales,
                                item.selling_price,
                                getDiscountLabel(selected_product)?.[0]?.promotions?.[0]?.gift?.[0]?.value,
                                getDiscountLabel(selected_product)?.[0]?.promotions?.[0]?.gift?.[0]))
                              }}
                            </div>
                          </div>
                          <div v-else>
                            {{ isLoyalty ? '' : 'Rp' }}
                            {{ toCurrency(item.selling_price) }}
                          </div>
                        </div>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </div>
              </div>
            </div>
            <div v-if="unitSelected?.selling_price" class="price mt-2">
              {{
                `${isLoyalty ? '' : 'Rp'} ${toCurrency(qty * calculatePrice(unitSelected.calc_price_sales_discount, unitSelected.percentage_price_sales,
                unitSelected.selling_price,
                getDiscountLabel(selected_product)?.[0]?.promotions?.[0]?.gift?.[0]?.value,
                getDiscountLabel(selected_product)?.[0]?.promotions?.[0]?.gift?.[0]))}`
              }}
            </div>
          </div>
          <div v-else>
            <div class="py-2 px-3 d-flex align-items-center mt-4"
              style="background-color: #F4F4F4; border-radius: 12px;">
              <Information :color="'#000'" />
              <span class="ml-2" style="color: #000">
                {{ $t('purchase.catalogue.view_only') }}
                <router-link class="mb-3" :to="{ path: `/page/customer-service` }">
                  <span class="nama_kategori" style="cursor: pointer">customer services</span>
                </router-link>
              </span>
            </div>
          </div>
        </template>
        <div class="required-qty-modal" v-if="showDisclaimerQty">{{ $t('purchase.catalogue.qty_req') }}</div>

        <div v-if="unitList?.length > 0">
          <button type="button" class="button-add" :disabled="loading || (!stock && !isPreorder) || !unitSelected"
            @click.prevent="() => handleAddToCart()">
            <a-icon v-if="loading" type="loading" />
            <template v-else>
              <i class="fe fe-plus mr-2" /> {{ $t('purchase.catalogue.cart') }}
            </template>
          </button>
        </div>
      </div>
    </a-modal>
    <ModalPreventAddCart :showModal="showNotificationPreventAddCart"
      @emitCloseModal="(e) => showNotificationPreventAddCart = e" />
  </div>
</template>

<script>

import ModalPreventAddCart from '@/components/Purchase/ModalPreventAddCart.vue'
import Information from '@/components/Icons/Information.vue'

export default {
  props: ['visible', 'product', 'price', 'selected_product', 'isLoyalty', 'dataDiscount'],
  components: {
    ModalPreventAddCart,
    Information,
  },
  data() {
    return {
      qty: 1,
      unitSelected: null,
      selectedVariant: null,
      variants: null,
      unitList: [],
      showDisclaimerQty: false,
      stock: null,
      isPreorder: false,
      showNotificationPreventAddCart: false,
      selectedWarehouse: undefined,
    }
  },
  computed: {
    visbile_modal: {
      get: function () {
        return this.visible
      },
      // setter
      set: function (newValue) {
        //
      },
    },
    loading() {
      return this.$store.state.purchase.loading
    },
  },
  methods: {
    getBaseQtyProduct(catalog_id, whsId) {
      this.$store.dispatch('warehouse/CHECKINVENTORY', {
        channel_id: this.isLoyalty ? this.$store.state.app.redeem_channel_id : this.$store.state.app.channel_id,
        warehouse_id: whsId?.toString(),
        catalog_id: catalog_id,
      })
        .then(({ data }) => {
          this.stock = data[0].sellable < 0 ? 0 : data[0].sellable
          this.isPreorder = data[0]?.pre_order
        })
        .catch((e) => {
          console.error({ e })
          this.stock = null
          this.isPreorder = false
        })
    },
    updateQty(param) {
      if (!this.stock) {
        return this.$notification.warning({
          message: 'Stok Habis',
        })
      }
      if (param === 'min' && this.qty > 1) {
        this.qty = this.qty - 1
      } else if (param === 'add' && String(this.qty + 1).length <= 4) {
        this.qty = this.qty + 1
      } else {
        if (String(param.target.value).length <= 4) {
          if (param.target.value === '' || param.target.value === '0') {
            this.showDisclaimerQty = true
            this.qty = ''
          } else {
            this.showDisclaimerQty = false
            this.qty = parseInt(param.target.value)
          }
        }
        this.$forceUpdate()
      }
    },
    handleNullQty(param) {
      if (param.target.value === '' || param.target.value === '0') {
        this.qty = 1
        this.showDisclaimerQty = false
      }
    },
    validateQty(event) {
      const regex = new RegExp(/^[\d]*$/g)
      if (!regex.test(event.key)) {
        event.preventDefault()
      }
    },
    handleSelectUom({ key }) {
      this.unitSelected = this.unitList.find(item => item.uom === key) || null
    },
    handleChangeVariant(id) {
      this.selectedVariant = this.selected_product.catalogs.find(item => item.id === id)
      this.createUnitList(this.selected_product.id, this.selectedVariant.id)
    },
    handleGetNewCart() {
      this.$store.dispatch(this.$store.getters['user/isGuest'] ? 'purchase/GETCARTGUEST' : 'purchase/GETCART', {
        channel_id: this.isLoyalty ? this.$store.state.app.redeem_channel_id : this.$store.state.app.channel_id,
      })
        .catch((err) => console.error(err))
        .finally(() => {
          this.loading = false
        })
    },
    handleGetNewCartLoyalty() {
      this.$store.dispatch('purchase/GETCARTLOYALTY', {
        channel_id: this.$store.state.app.redeem_channel_id || 625,
      })
        .catch((err) => console.error(err))
        .finally(() => {
          this.loading = false
        })
    },
    handleAddToCart() {
      if (!this.unitSelected) {
        return this.$notification.warning({
          message: this.$t('purchase.cart.notif_select_unit'),
        })
      }
      if (this.$store.state.account.detailCustomer.is_active) {
        let payload = {
          cart_id: this.$store.state.purchase.cartId,
          item_id: this.selectedVariant.id,
          product_id: this.selected_product.id,
          catalog_sku: this.selectedVariant.sku,
          catalog_title: this.selectedVariant.title,
          showimg_url: this.selected_product.showimg_url ?? this.selected_product.showing_url,
          qty: this.qty,
          uom: this.unitSelected && this.unitSelected.uom,
          buyer_id: this.$store.state.user.user_data.buyer_id,
          area_id: this.$store.state.user.user_data.area_id,
          // price_id: this.unitSelected && this.unitSelected.id,
          item_price: this.unitSelected && this.unitSelected.selling_price,
          currency: this.unitSelected && this.unitSelected.currency,
          warehouse_id: this.selectedWarehouse,
          // category_id: this.selected_product.category,
        }
        if (this.$store.getters['user/isGuest']) {
          payload = {
            ...payload,
            buyer_id: null,
          }
        }
        this.$store.dispatch(this.$store.getters['user/isGuest'] ? 'purchase/ADDTOCARTGUEST' : 'purchase/ADDTOCART', {
          ...payload,
          channel_id: this.isLoyalty ? this.$store.state.app.redeem_channel_id : this.$store.state.app.channel_id,
        })
          .then(({ data }) => {
            this.$notification.success({
              message: this.$t('purchase.cart.success'),
            })
            this.isLoyalty ? this.handleGetNewCartLoyalty() : this.handleGetNewCart()
            this.$emit('controlModalAddToCart', false)
          })
          .catch((err) => {
            const { data } = err.response
            this.$notification.error({
              message: this.$t('purchase.cart.failed'),
              description: data && data.message ? data.message : 'Internal Server Error',
            })
          })
      } else {
        this.showNotificationPreventAddCart = true
      }
    },
    createUnitList(product_id, catalog_id) {
      if (!product_id || !catalog_id) {
        return
      }
      this.unitSelected = null
      this.getBaseQtyProduct(catalog_id, this.selectedWarehouse)
      const product = this.price.find(item => item.product_id === product_id && item.catalogs.find(value => value.id === catalog_id))
      const price = product?.catalogs?.find(item => item.id === catalog_id)
      const isNOO = Boolean(!this.$store.state.user.user_data.seller?.length)
      if (isNOO) {
        this.unitList = price ? price.price_list : []
      } else {
        const findSelectedPriceArea = this.$store.state.user.user_data.seller.find((obj) => obj.warehouse_id === this.selectedWarehouse)
        const filteredPriceList = price.price_list.filter((obj) => obj.price_area_id === findSelectedPriceArea.price_area_id)
        this.unitList = filteredPriceList?.length > 0 ? filteredPriceList : []
      }
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('id-ID').format(value || 0)}`
    },
    calculatePrice(calc_price_sales_discount, percentage_price_sales, selling_price, discount, discount_type) {
      // if (discount) {
      // }
      if (percentage_price_sales !== null) {
        if (calc_price_sales_discount?.toString() === 'before') {
          const price_before = selling_price - (selling_price * (percentage_price_sales / 100))
          if (!discount) {
            const price = price_before - (price_before * (discount / 100))
            return price
          } else {
            return price_before
          }
        } else if (calc_price_sales_discount?.toString() === 'after') {
          const price_after = selling_price - (selling_price * (discount / 100))
          if (!discount) {
            const price = price_after - (price_after * (percentage_price_sales / 100))
            return price
          } else {
            return price_after
          }
        } else {
          const price = selling_price - (selling_price * (discount / 100))
          return price
        }
      } else if (percentage_price_sales === null) {
        if (!discount) {
          return selling_price
        } else {
          let discountValue = 0
          if (discount_type?.value_type === 'fixed') {
            discountValue = discount
          } else {
            discountValue = selling_price * (discount / 100)
            if (discountValue > discount_type?.max_value) {
              discountValue = discount_type?.max_value
            }
          }
          let price = selling_price - discountValue
          if (price < 0) {
            price = 0
          }
          return price
        }
      }
    },
    getDiscountLabel(param) {
      const filter = this.dataDiscount.filter(v => { return v.catalog_id === this.selectedVariant.id })
      if (filter.length > 0) {
        if (filter?.[0]?.promotions?.[0]?.gift?.[0]?.value_type) {
          return filter
        }
        return []
      }
      return []
    },
  },
  watch: {
    selected_product: {
      immediate: true,
      handler: function (value) {
        // const selected = []
        // if (Array.isArray(value.catalogs)) {
        //   value.catalogs.forEach((item) => {
        //     if (item.options || item.option) {
        //       const title = []
        //       let varian = ''
        //       const option = item.options || item.option
        //       for (const property in option) {
        //         varian = property
        //         title.push(option[property])
        //       }

        //       selected.push({ title: title.join(' '), catalog_id: item.id, varian })
        //     } else {
        //       const product_title = value.title.split(' ')
        //       const catalog_title = item.title.split(' ')

        //       // Hanya logic untuk case tidak ada option di catalogs
        //       // karena setiap catalog_title itu adalah product_title + variantnya.
        //       // maka dibuatlah logic seperti ini.

        //       const data = {
        //         title: [],
        //         catalog_id: item.id,
        //       }

        //       catalog_title.forEach((item, index) => {
        //         if (item !== product_title[index]) {
        //           data.title.push(item)
        //         }
        //       })

        //       selected.push({ ...data, title: data.title.length ? data.title.join(' ') : item.title })
        //     }
        //   })
        // }

        // this.variants = selected
        // this.selectedVariant = value.catalogs[0]
      },
    },
    selectedWarehouse: {
      immediate: true,
      deep: true,
      handler: function (value) {
        if (value) {
          this.unitSelected = null
          this.createUnitList(this.selected_product && this.selected_product.id, this.selectedVariant && this.selectedVariant.id)
        }
      },
    },
    visbile_modal: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value) {
          this.unitSelected = null
          this.selectedVariant = null
          if (this.selected_product?.warehouses?.length === 1) {
            this.selectedWarehouse = this.selected_product?.warehouses.at(0)?.id
          } else {
            this.selectedWarehouse = undefined
          }

          const selected = []
          if (Array.isArray(this.selected_product.catalogs)) {
            this.selected_product.catalogs.forEach((item) => {
              if (item.options || item.option) {
                const title = []
                let varian = ''
                const option = item.options || item.option
                for (const property in option) {
                  varian = property
                  title.push(option[property])
                }

                selected.push({ title: title.join(' '), catalog_id: item.id, varian })
              } else {
                const product_title = this.selected_product.title.split(' ')
                const catalog_title = item.title.split(' ')

                // Hanya logic untuk case tidak ada option di catalogs
                // karena setiap catalog_title itu adalah product_title + variantnya.
                // maka dibuatlah logic seperti ini.

                const data = {
                  title: [],
                  catalog_id: item.id,
                }

                catalog_title.forEach((item, index) => {
                  if (item !== product_title[index]) {
                    data.title.push(item)
                  }
                })
                const alreadyPushedVariant = selected.find(item => item.catalog_id === data.catalog_id)
                if (!alreadyPushedVariant) {
                  selected.push({ ...data, title: data.title.length ? data.title.join(' ') : item.title })
                }
              }
            })
          }

          this.variants = selected
          this.selectedVariant = this.selected_product.catalogs[0]
          this.createUnitList(this.selected_product && this.selected_product.id, this.selectedVariant && this.selectedVariant.id)
        }
      },
    },
  },
}
</script>

<style lang="scss">
.ant-modal {

  .ant-modal-body {
    height: fit-content;
    background: #FFFFFF !important;
    border-radius: 5px !important;

    @media (max-width: 600px) {
      width: 100%
    }
  }

  .ant-modal-content {
    background: #FFFFFF !important;
    border-radius: 5px !important;

    @media (max-width: 567px) {
      width: auto !important;
      height: auto !important;
    }

    .select-box {
      height: 36px;
      padding: .5rem;
      border: 0.5px solid #585858;
      box-sizing: border-box;
      border-radius: 2px !important;
    }

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }

    .variant {
      &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }

      &__option {
        width: auto;
        height: 27px;
        background: #F4F4F4;
        border-radius: 8px;
        padding: .5rem;
        cursor: pointer;
      }

      .active {
        background: rgba(255, 104, 94, 0.05) !important;
        border: 1px solid #FF685E;
        color: #FF685E;
      }
    }
  }
}

.variant_title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #777777;
}

.variant_option {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  width: auto;
  height: 26px;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  border-radius: 6px;
  color: #777777;
}

.choose_variant {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.sku {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #999999;
}

.centered-input {
  text-align: center;
  border: 0px !important;
}

.required-qty-modal {
  text-align: center;
  font-size: 12px;
  color: #ff0000;
  margin-top: 2px;
}

.input-add-cart {
  height: fit-content !important;
}
</style>

<style lang="scss" scoped>
.product {
  &-detail {
    display: flex;

    img {
      width: 80px;
      flex-shrink: 0;
      margin-right: 1rem;
    }

    .title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      line-height: 1.5;
    }
  }
}

.button-add {
  width: 100%;
  height: 40px;
  font-style: normal;
  font-weight: 500;
  color: #FFFFFF;
  background: var(--biz-brand-button);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;

  &[disabled] {
    opacity: .5;
  }
}

.price {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  // margin-top: 10px;
  font-family: 'Open Sans';
  text-transform: capitalize;
  color: var(--biz-brand-legacy);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // margin-top: auto;

  @media (max-width: 576px) {
    font-size: 12px;
    margin-right: 2px;
  }

  &.slash {
    color: #a7a9c9;
    font-size: .9rem;
    text-decoration-line: line-through;
    margin-bottom: .1rem;
  }

  &.disc {
    color: #a7a9c9;
    font-size: .7rem;
    // margin-bottom: -.5rem;
    margin-bottom: .1rem;
    text-decoration: line-through;
  }
}

.nama_kategori {
  color: black;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.nama_kategori:hover {
  font-weight: bold;
  text-decoration: underline;
}
</style>
